* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: Roboto,sans-serif;
  color: #FF7057;
  min-height: 100%;
}

header {
  background: #240D50;
  color: #FF7057;
  font-family: Roboto,sans-serif;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form > div {
  width: 100%;
  margin-bottom: 1em;
}
form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #FF7057;
}

button {
  background: #FF7057;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
button:hover {
  filter: brightness(150%);
}

.room {
  position: relative;
}
.room button {
  position: absolute;
  top: 0;
  right: 20px;
}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
}
.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 2em 2em;
}
.participant {
  /* background: #FF7057; */
  /* padding: 10px; */
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
}
.participant:last-child {
  margin-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

video {
  width: 100%;
  /* max-width: 600px; */
  object-fit: fill;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
}

footer {
  background: #FF7057;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}
